<style lang="scss">
.exp-bg {
  height: 246px;
  padding: 15px 20px;
  background: url("../assets/images/exp-bg.png");
  background-size: cover;
}

.exp-spent {
  font-size: 28px;
  font-weight: bold;
  color: #F8E71C;
  padding-left: 8px;
  letter-spacing: 2px;
}

.exp-status {
  font-size: 18px;
  font-weight: bold;
  color: #8FD918;
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.exp-status-dot {
  width: 8px;
  height: 8px;
  background: #8FD918;
  border-radius: 4px;
  margin-right: 5px;
  display: block;
}

.exp-title {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
}

.exp-title-line {
  display: flex;
  align-items: center;
  //justify-content: center;
}

.exp-spent-line {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

.exp-info-line {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.exp-info-right {
  flex: 1;
  text-align: right;
}

.exp-infos {
  margin-top: 26px;
}

.finish-btn {
  width: 265px;
  height: 36px;
  background: #FF4158;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border: 0;
  margin-top: 10px;
}

.exp-wrapper {
  //position: absolute;
  //left: 0;
  //top: 46px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  background: #fff;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.exp-line-status-circle {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 3px;
  margin-right: 4px;
}

.applier {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 15px;
}

.do-score {
  font-size: 14px;
  color: #4A89DC;
}

.view-score {
  font-size: 14px;
  color: #F86719;
}

.confirm-finish {
  width: 80%;
  left: 10%;
  background: #fff;
}

.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }
}
.btn-button{
  width: 50%;
  border: 1px solid #409EFF;
  color: #97a4af !important;
}
</style>
<template>
  <div>
    <AppHeader  title="信息展示"/>
    <div class="exp-wrapper">
      <div class="exp-bg">
        <div class="exp-title-line">
          <div class="exp-title">{{ curExp.equipmentName }}</div>
          <div class="exp-status"><span class="exp-status-dot"></span>实验中</div>
        </div>
        <div class="exp-spent-line">已实验<span class="exp-spent">{{ curExp.spent }}</span></div>

        <div class="exp-infos">
          <div class="exp-info-line">
            <div class="exp-info-left">实验人：{{ curExp.userName }}</div>
            <div class="exp-info-right">实验地点：{{ curExp.eqLocation }}</div>
          </div>
          <div class="exp-info-line">
            上机时间：{{ formatDate(curExp.beginAt) }}
          </div>
          <div class="exp-info-line">
            预约时间：{{ beginEnd(curExp.booking.beginAt, curExp.booking.endAt) }}
          </div>
        </div>
        <div class="flex-center">
          <button class="finish-btn" @click="reback()">返回</button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import Panel from "@/components/Panel";
import {apiUrl} from "@/plugins/constants";

let intervalId
export default {
  components: {
    Panel,
    AppHeader,
    InfiniteScroll
  },
  data() {
    return {
      page: 1,
      exps: [],
      loading: false,
      noMore: false,
      curExp: null,
      popupVisible: false,
      finalAccounts: false,
      isNff: false
    }
  },
  mounted() {
 /*   this.initExps()*/
    this.isNff = apiUrl.indexOf('nffgz-equiot.hkust-gz.edu')>-1
    this.getExpStatusByEquId()
  },
  methods: {
    initExps() {
      this.curExp = null
      this.exps = []
      this.page = 1
      this.noMore = false
      this.loadExps()
    },
    loadExps() {
      if (this.noMore) {
        return
      }
      const that = this
      var eqId = this.$route.query.equipmentId
      var bookingId = this.$route.query.bookingId
      console.log("eqId",eqId)
      console.log("bookingId",bookingId)
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.$http.post(`/api/experiment/list`, data).then(res => {
        if (res.data && res.data.length > 0) {
          if (!that.curExp) {

            if(eqId){

              this.queryexperimentByeqId(eqId,bookingId)
            }
          }

          const exps = res.data
          for (var i=0;i<exps.length;i++){
            if(exps[i].endAt==null){

            }
          }
          that.exps = that.exps.concat(exps)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    beginEnd(beginAt, endAt) {
      let beginYMD = this.$moment(beginAt).format('YYYY.MM.DD')
      let result = this.$moment(beginAt).format('YYYY.MM.DD HH:mm')
      if (endAt) {
        let endYMD = this.$moment(endAt).format('YYYY.MM.DD')
        if (endYMD == beginYMD) {
          result += ("-" + this.$moment(endAt).format('HH:mm'))
        } else {
          result += ("-" + this.$moment(endAt).format('YYYY.MM.DD HH:mm'))
        }
      } else {
        result += "-"
      }
      return result
    },
    displaySpent(spent) {

      let totalSeconds = Math.floor(spent / 1000)
      let hours = Math.floor(totalSeconds / 3600)
      let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      let seconds = totalSeconds - hours * 3600 - minutes * 60
      return this.fillZero(hours) + ':' + this.fillZero(minutes) + ':' + this.fillZero(seconds)
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY.MM.DD HH:mm')
    },
    fillZero(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    viewScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id,
          view: true
        }
      })
    },
    //查看关联的笔记
    viewNotes(exp){
      this.$router.push({
        path: '/exp-markdown-list',
        query: {
          experimentId: exp.id
        }
      })
    },
    doScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id
        }
      })
    },
    doEnd(id){
      console.log("结束ID",id)
      const that = this
      this.$http.post(`/api/experiment/end?id=${id}&finalAccounts=${that.finalAccounts}&operationByUser=true`).then(res => {
        that.initExps()
      })
    },
    endExp() {
      const that = this
      that.popupVisible = true
    },
    cancelEnd(){
      this.popupVisible = false
    },
    getExpStatusByEquId(){
      var that=this;
      this.$http.get('/api/experiment/getExperimentMsgByEquId?equipmentId='+this.$route.query.equipmentId).then(res =>{
        if(res.data && res.data.length > 0){
          let latestExp = res.data[0]
          if (latestExp.endAt <= 0) {
            that.curExp = latestExp
            that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            intervalId = window.setInterval(function () {
              that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            }, 1000)
          }
        }
      })
    },
    reback(){
      this.$router.push({
        path: '/index2',
      })
    },
    queryexperimentByeqId(eqId,bookingId){
      const that = this
      if (intervalId) {
        window.clearInterval(intervalId)
      }
      this.$http.post(`/api/experiment/infoByEquipmentId?equipmentId=`+eqId+"&bookingId="+bookingId).then(res=>{
        console.log("res.experiment",res)

        if(res.data && res.data.length > 0){

          let latestExp = res.data[0]
          console.log("endAt",latestExp.endAt)
          if (latestExp.endAt <= 0) {
            console.log("正在试验")
            that.curExp = latestExp
            console.log("that.curExp2",that.curExp)
            that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            intervalId = window.setInterval(function () {
              that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            }, 1000)
          }
        }


      })
    }
  }

}
</script>
